.app {
  margin: 50px;
  margin-bottom: 21%;
}
.search {
  padding: 10px;
  font-size: 20px;
  margin-left: 42%;
}
.list {
  list-style: none;
  padding: 0;
  text-align: center;
}
.listitem {
  margin-bottom: 20px;
  font-size: 25px;
  color: #444;
  font-weight: 300;
}
